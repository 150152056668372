<template>
	<div id="ruleList" v-loading="loading" :element-loading-text="loadingText"
		element-loading-background="rgba(0, 0, 0, 0)">
		<div class="addStyle">
			<p class="tabelTitle">正题</p>
			<el-button size="small" type="warning" @click="addrule('formal')">添加正题规则</el-button>
		</div>
		<el-table :cell-style="rowClass" ref="multipleTable" :header-cell-style="headClass" :data="formalData" border
			stripe :summary-method="getSummaries1" show-summary max-height="400">
			<template slot="empty">
				<span v-show="loading">加载中...</span>
				<span v-show="!loading">暂无数据</span>
			</template>
			<el-table-column prop="scope" label="试题范围" :show-overflow-tooltip="true">
				<template slot-scope="scope">
					<span v-if="currentIndex===scope.$index && rule=='formal'">
						<el-select v-model="scope.row.scopeId" filterable placeholder="请选择试题范围" class="selectStyle"
							@change="getQuizCount('formal',scope.$index,scope.row)">
							<el-option v-for="item in scopeData" :key="item.id" :label="item.scope" :value="item.id">
							</el-option>
						</el-select>
					</span>
					<span v-else>{{scope.row.scope || '--'}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="typeStr" label="题型" :show-overflow-tooltip="true">
				<template slot-scope="scope">
					<span v-if="currentIndex===scope.$index && rule=='formal'">
						<el-select v-model="scope.row.type" filterable placeholder="请选择题型" class="selectStyle"
							@change="getQuizCount('formal',scope.$index,scope.row)">
							<el-option v-for="item in typeData" :key="item.id" :label="item.type" :value="item.id">
							</el-option>
						</el-select>
					</span>
					<span v-else>{{scope.row.typeStr || '--'}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="difficultyStr" label="难度" :show-overflow-tooltip="true">
				<template slot-scope="scope">
					<span v-if="currentIndex === scope.$index && rule=='formal'">
						<el-select v-model="scope.row.difficulty" filterable placeholder="请选择试题难度" class="selectStyle"
							@change="getQuizCount('formal',scope.$index,scope.row)">
							<el-option v-for="item in difficultyData" :key="item.id" :label="item.difficulty"
								:value="item.id">
							</el-option>
						</el-select>
					</span>
					<span v-else>{{scope.row.difficultyStr || '--'}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="quizCount" label="匹配数量" :show-overflow-tooltip="true"
				v-if="(status=='add'||status=='edit') && rule=='formal'">
				<template slot-scope="scope">
					<span v-if="currentIndex!=scope.$index">--</span>
					<span v-else>{{scope.row.quizCount}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="count" label="题的数量" :show-overflow-tooltip="true">
				<template slot-scope="scope">
					<span v-if="currentIndex===scope.$index && rule=='formal'">
						<el-input v-model="scope.row.count" placeholder="请输入题的数量"></el-input>
					</span>
					<span v-else>{{scope.row.count || '--'}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="unitScore" label="每题分数" :show-overflow-tooltip="true">
				<template slot-scope="scope">
					<span v-if="currentIndex===scope.$index && rule=='formal'">
						<el-input v-model="scope.row.unitScore" placeholder="请输入每题分数"></el-input>
					</span>
					<span v-else>{{scope.row.unitScore || '--'}}</span>
				</template>
			</el-table-column>
			<el-table-column label="操作" fixed="right" width="100">
				<template slot-scope="scope">
					<span v-if="currentIndex===scope.$index && rule=='formal'">
						<el-button type="text" style="color:#409EFF;"
							@click="confirmClick('formal',scope.$index,scope.row)">确定
						</el-button>
						<el-button type="text" style="color:#303133;"
							@click="cancelClick('formal',scope.$index,scope.row)">取消
						</el-button>
					</span>
					<span v-else>
						<el-button type="text" style="color:#e6a23c;"
							@click="editrule('formal',scope.$index,scope.row)">编辑
						</el-button>
						<el-button type="text" style="color:#f56c6c;"
							@click="deleterule('formal',scope.$index,scope.row)">删除
						</el-button>
					</span>
				</template>
			</el-table-column>
		</el-table>
		<!-- 附加题 -->
		<br>
		<div class="addStyle">
			<p class="tabelTitle">附加题</p>
			<el-button size="small" type="warning" @click="addrule('extra')">添加附加题规则</el-button>
		</div>
		<el-table :cell-style="rowClass" ref="multipleTable" :header-cell-style="headClass" :data="extraData" border
			stripe :summary-method="getSummaries2" show-summary max-height="300">
			<template slot="empty">
				<span v-show="loading">加载中...</span>
				<span v-show="!loading">暂无数据</span>
			</template>
			<el-table-column prop="scope" label="试题范围" :show-overflow-tooltip="true">
				<template slot-scope="scope">
					<span v-if="currentIndex===scope.$index && rule=='extra'">
						<el-select v-model="scope.row.scopeId" filterable placeholder="请选择试题范围" class="selectStyle"
							@change="getQuizCount('extra',scope.$index,scope.row)">
							<el-option v-for="item in scopeData" :key="item.id" :label="item.scope" :value="item.id">
							</el-option>
						</el-select>
					</span>
					<span v-else>{{scope.row.scope || '--'}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="typeStr" label="题型" :show-overflow-tooltip="true">
				<template slot-scope="scope">
					<span v-if="currentIndex===scope.$index && rule=='extra'">
						<el-select v-model="scope.row.type" filterable placeholder="请选择题型" class="selectStyle"
							@change="getQuizCount('extra',scope.$index,scope.row)">
							<el-option v-for="item in typeData" :key="item.id" :label="item.type" :value="item.id">
							</el-option>
						</el-select>
					</span>
					<span v-else>{{scope.row.typeStr || '--'}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="difficultyStr" label="难度" :show-overflow-tooltip="true">
				<template slot-scope="scope">
					<span v-if="currentIndex===scope.$index && rule=='extra'">
						<el-select v-model="scope.row.difficulty" filterable placeholder="请选择试题难度" class="selectStyle"
							@change="getQuizCount('extra',scope.$index,scope.row)">
							<el-option v-for="item in difficultyData" :key="item.id" :label="item.difficulty"
								:value="item.id">
							</el-option>
						</el-select>
					</span>
					<span v-else>{{scope.row.difficultyStr || '--'}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="quizCount" label="匹配数量" :show-overflow-tooltip="true"
				v-if="(status=='add'||status=='edit') && rule=='extra'">
				<template slot-scope="scope">
					<span v-if="currentIndex!=scope.$index">--</span>
					<span v-else>{{scope.row.quizCount}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="count" label="题的数量" :show-overflow-tooltip="true">
				<template slot-scope="scope">
					<span v-if="currentIndex===scope.$index && rule=='extra'">
						<el-input v-model="scope.row.count" placeholder="请输入题的数量"></el-input>
					</span>
					<span v-else>{{scope.row.count || '--'}}</span>
				</template>
			</el-table-column>
			<el-table-column prop="unitScore" label="每题分数" :show-overflow-tooltip="true">
				<template slot-scope="scope">
					<span v-if="currentIndex===scope.$index && rule=='extra'">
						<el-input v-model="scope.row.unitScore" placeholder="请输入每题分数"></el-input>
					</span>
					<span v-else>{{scope.row.unitScore || '--'}}</span>
				</template>
			</el-table-column>
			<el-table-column label="操作" fixed="right" width="100">
				<template slot-scope="scope">
					<span  v-if="currentIndex===scope.$index && rule=='extra'">
						<el-button type="text" style="color:#409EFF;"
							@click="confirmClick('extra',scope.$index,scope.row)">确定
						</el-button>
						<el-button type="text" style="color:#303133;"
							@click="cancelClick('extra',scope.$index,scope.row)">取消
						</el-button>
					</span>
					<span v-else>
						<el-button type="text" style="color:#e6a23c;"
							@click="editrule('extra',scope.$index,scope.row)">编辑
						</el-button>
						<el-button type="text" style="color:#f56c6c;"
							@click="deleterule('extra',scope.$index,scope.row)">删除
						</el-button>
					</span>
				</template>
			</el-table-column>
		</el-table>
		<el-button v-if="ruleConfirmed===false" class="ruleConfirmed" type="primary" size="small" @click="ruleConfirmedChange">
			锁定试卷规则</el-button>
	</div>
</template>

<script>
	export default {
		name: 'RuleList',
		data() {
			return {
				// 加载中
				loading: false, //加载中
				loadingText: "",
				// 规则data数据
				formalData: [],
				extraData: [],
				id: "", //试卷id
				ruleConfirmed: null, //当前是否需要锁定的状态
				rule: "", //当前是正题啊=还是附加题
				status: "", //当前状态add,edit
				currentIndex: 'test-测试',
				cacheType: '',
				cacheDifficulty: '',
				cacheScopeId: '',
				cacheCount: '',
				cacheUnitScore: '',
				// 考试范围数组
				scopeData: [],
				// 难易度
				difficultyData: [{
					difficulty: "简单",
					id: 1
				}, {
					difficulty: "普通",
					id: 2
				}, {
					difficulty: "困难",
					id: 3
				}],
				// 类型
				typeData: [{
					type: "单选",
					id: 1
				}, {
					type: "多选",
					id: 2
				}, {
					type: "填空",
					id: 3
				}],

			}
		},
		methods: {
			// 正题总分
			getSummaries1(param) {
				const {
					columns,
					data
				} = param;
				const sums = [];
				let values = [];
				columns.forEach((column, index) => {
					if (index === 0) {
						sums[index] = '正题总分';
						// : '附加题总分
						return;
					}
					if (column.property == 'count' || column.property == 'unitScore') {
						values = data.map(item => Number(item.count * item.unitScore));
					}
					if (index === 4 && (!values.every(value => isNaN(value)))) {
						sums[index] = values.reduce((prev, curr) => {
							const value = Number(curr);
							if (!isNaN(value)) {
								return prev + curr;
							} else {
								return prev;
							}
						}, 0);
						sums[index];
					} else {
						sums[index] = '/';
					}
				});
				return sums;
			},
			// 附加题总分
			getSummaries2(param) {
				const {
					columns,
					data
				} = param;
				const sums = [];
				let values = [];
				columns.forEach((column, index) => {
					if (index === 0) {
						sums[index] = '附加题总分';
						return;
					}
					if (column.property == 'count' || column.property == 'unitScore') {
						values = data.map(item => Number(item.count * item.unitScore));
					}
					if (index === 4 && (!values.every(value => isNaN(value)))) {
						sums[index] = values.reduce((prev, curr) => {
							const value = Number(curr);
							if (!isNaN(value)) {
								return prev + curr;
							} else {
								return prev;
							}
						}, 0);
						sums[index];
					} else {
						sums[index] = '/';
					}
				});
				return sums;
			},
			// 获取匹配的数量
			getQuizCount(rule, index, row) {
				var that = this;
				that.rule = rule;
				var ruleData = that.rule === 'formal' ? JSON.parse(JSON.stringify(that.formalData)) : JSON.parse(JSON.stringify(that.extraData));
				if (row.scopeId) {
					that.$axios.post("/category/rule/getQuizCount", {
						type: row.type,
						difficulty: row.difficulty,
						scopeId: row.scopeId
					}).then(function(response) {
						ruleData[index].quizCount = response.data.count;
					}).catch(function(error) {
						ruleData[index].quizCount = 0;
					});
				} else {
					ruleData[index].quizCount = 0;
				}
				setTimeout(function() {
					if (rule === 'formal') {
						that.formalData = JSON.parse(JSON.stringify(ruleData));
					} else {
						that.extraData = JSON.parse(JSON.stringify(ruleData));
					}
				}, 500);
			},
			// 关闭弹框重置data
			initData() {
				Object.assign(this.$data, this.$options.data())
			},
			// 获取考试范围
			getScopeData() {
				let self = this;
				self.$axios.post("/quizScope/list", {
					keyword: ""
				}).then(function(response) {
					self.scopeData = response.data;
				}).catch(function(error) {
					self.scopeData = [];
				});
			},
			//表格样式
			headClass() {
				return "text-align: center;background:#D7A24A; color:#ffffff";
			},
			rowClass() {
				return "text-align: center;";
			},
			// 添加试卷规则
			addrule(rule) {
				var that = this;
				// 判断第一次点击正题和第二次点击附加题点击不一致情况
				var ruleData=[];
				var ruleDataOld=[];
				//现在的rule
				ruleData = rule === 'formal' ? JSON.parse(JSON.stringify(that.formalData)) : JSON.parse(JSON.stringify(that.extraData));
				// 若存在添加
				if (that.status == "add") {
					return
				}
				// 若存在编辑
				else if (that.status == "edit") {
					//上次点击的rule
					ruleDataOld = that.rule === 'formal' ? JSON.parse(JSON.stringify(that.formalData)) : JSON.parse(JSON.stringify(that.extraData));
					ruleDataOld[that.currentIndex].type = that.cacheType;
					ruleDataOld[that.currentIndex].difficulty = that.cacheDifficulty;
					ruleDataOld[that.currentIndex].scopeId = that.cacheScopeId;
					ruleDataOld[that.currentIndex].count = that.cacheCount;
					ruleDataOld[that.currentIndex].unitScore = that.cacheUnitScore;
				}
				var list = {};
				that.status = "add";
				setTimeout(function() {
					that.currentIndex = ruleData.length;
					if(that.rule==''){
						ruleData.push(list);
						if (rule === 'formal') {
							that.formalData = JSON.parse(JSON.stringify(ruleData));
						} else {
							that.extraData = JSON.parse(JSON.stringify(ruleData));
						}
					}else if(that.rule==rule){
						ruleDataOld.push(list);
						if (rule === 'formal') {
							that.formalData = JSON.parse(JSON.stringify(ruleDataOld));
						} else {
							that.extraData = JSON.parse(JSON.stringify(ruleDataOld));
						}
					}else{
						ruleData.push(list);
						if (that.rule === 'formal') {
							that.formalData = JSON.parse(JSON.stringify(ruleDataOld));
							that.extraData = JSON.parse(JSON.stringify(ruleData));
						} else {
							that.extraData = JSON.parse(JSON.stringify(ruleDataOld));
							that.formalData = JSON.parse(JSON.stringify(ruleData));
						}
					}
					that.rule = rule;
				}, 400);
			},
			// 编辑试卷规则
			editrule(rule, index, row) {
				var that = this;
				// 若that.rule为空的情况下不用考虑，不会进入add和edit分支中
				var ruleData = that.rule === 'formal' ? that.formalData : that.extraData;
				// 若存在添加
				if (that.status == "add") {
					ruleData.pop();
				}
				// 若存在编辑
				else if (that.status == "edit") {
					ruleData[that.currentIndex].type = that.cacheType;
					ruleData[that.currentIndex].difficulty = that.cacheDifficulty;
					ruleData[that.currentIndex].scopeId = that.cacheScopeId;
					ruleData[that.currentIndex].count = that.cacheCount;
					ruleData[that.currentIndex].unitScore = that.cacheUnitScore;
				}
				that.status = "edit";
				that.rule = rule;
				that.currentIndex = index;
				that.cacheType = row.type;
				that.cacheDifficulty = row.difficulty;
				that.cacheScopeId = row.scopeId;
				that.cacheCount = row.count;
				that.cacheUnitScore = row.unitScore;
				that.getQuizCount(rule, index, row);
			},
			// 删除试卷规则
			deleterule(rule, index, row) {
				var that = this;
				that.$confirm('确定要删除此条规则吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					that.$axios.put("/category/rule/remove/" + row.ruleId)
						.then(res => {
							that.$notify({
								title: '提示',
								message: '规则删除成功!',
								type: 'success',
								duration: 2000
							});
							that.getRulesData(that.id);
						})
						.catch(err => {
							that.$notify({
								title: '失败',
								message: err,
								type: 'error',
								duration: 2000
							});
						})
				}).catch(() => {});
			},
			// 获取规则列表
			getRulesData(val) {
				var that = this;
				that.loading = true;
				that.currentIndex = "test-测试";
				that.status ="";
				that.id = val; //试卷id赋值
				that.$axios.get("/category/rule/list/" + val)
					.then(res => {
						that.formalData = res.data.formal;
						that.extraData = res.data.extra;
						//true已锁定 false未锁定
						that.ruleConfirmed = res.data.confirmed;
						that.loading = false;
						that.getScopeData();
					})
					.catch(err => {
						that.$notify({
							title: '失败',
							message: err,
							type: 'error',
							duration: 2000
						});
						that.loading = false;
					})
			},
			// 确定添加/编辑
			confirmClick(rule, index, row) {
				var that = this;
				// that.rule = rule;
				var url = "";
				var postData = {};
				var tip = "";
				if (that.status == 'add') {
					url = "/category/rule/add";
					tip = "添加";
					postData = {
						categoryId: that.id,
						type: row.type,
						difficulty: row.difficulty,
						scopeId: row.scopeId,
						count: parseInt(row.count),
						unitScore: parseInt(row.unitScore),
						extra: rule === 'formal' ? '' : 1
					};
				} else {
					url = "/category/rule/edit";
					tip = "编辑";
					postData = {
						ruleId: row.ruleId,
						categoryId: that.id,
						type: row.type,
						difficulty: row.difficulty,
						scopeId: row.scopeId,
						count: parseInt(row.count),
						unitScore: parseInt(row.unitScore),
						extra: rule === 'formal' ? '' : 1
					};
				}
				that.$axios.post(url, postData)
					.then(res => {
						that.getRulesData(that.id);
						that.$notify({
							title: '提示',
							message: '规则' + tip + '成功!',
							type: 'success',
							duration: 2000
						});
						that.currentIndex = "test-测试";
						that.status = "";
						that.rule='';
					})
					.catch(err => {
						that.$notify({
							title: '失败',
							message: err,
							type: 'error',
							duration: 2000
						});
					})
			},
			// 取消添加/编辑
			cancelClick(rule, index, row) {
				var that = this;
				var ruleData = rule === 'formal' ? that.formalData: that.extraData;
				// 若当前是添加状态
				if (that.status == 'add') {
					ruleData.pop();
				} else if (that.status == 'edit') {
					row.type = that.cacheType;
					row.difficulty = that.cacheDifficulty;
					row.scopeId = that.cacheScopeId;
					row.count = that.cacheCount;
					row.unitScore = that.cacheUnitScore;
				}
				that.currentIndex = "test-测试";
				that.rule = "";
				that.status = "";
			},
			// 确定规则的提交
			ruleConfirmedChange() {
				var that = this;
				// 只能锁定不需要解锁
				that.loading = true;
				// var url = row.ruleConfirmedValue ? "/category/rule/unlock/" : "/category/rule/confirm/";
				// var tip = row.ruleConfirmedValue ? "试卷规则解锁成功！" : "试卷规则锁定成功！";
				var url = "/category/rule/confirm/";
				var tip = "试卷规则锁定成功！";
				that.$axios
					.post(url + that.id)
					.then(function(res) {
						that.loading = false;
						that.$notify({
							title: '提示',
							message: tip,
							type: 'success',
							duration: 2000
						});
						that.ruleConfirmed=true;
						// that.handleCurrentChange(that.pageNum);
					})
					.catch(function(error) {
						that.loading = false;
						that.$notify({
							title: '失败',
							message: error,
							type: 'error',
							duration: 2000
						});
					});
			},
		}
	}
</script>

<style lang='scss' scoped>
	#ruleList {
		.addStyle {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.tabelTitle {
			text-align: left;
			font-size: 16px;
		}

		.ruleConfirmed {
			margin: 20px auto;
		}
	}
</style>
